import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_API_URL;

const triggerCleverTapEvent = async (
  event_name = "",
  event_property = {},
  auth_token = "",
  partner_code = "",
  jwt_token = ""
) => {
  const device_id = localStorage.getItem("device_id");
  const headers = { auth_token, device_id, partner_code, jwt_token, source: "web" };
  if (device_id) {
    delete headers.partner_code;
  }
  await axios.put(
    `${BASE_URL}magneto/clevertap/send-event-from-web`,
    { event_name, event_property },
    {
      headers
    }
  );
};

export default triggerCleverTapEvent;
