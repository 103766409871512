import React, { useEffect } from "react";
import { useElements } from "../componentWrapper/context";
import SimpleBackdrop from "../../../components/sharedComponents/backdropLoader/loader";
import { redirectToYubi } from "../Redux/actions";
import WealthHeader from "./WealthHeader";
import "../Style/Yubi.scss";

function Yubi({ returnBackToApp }) {
  const { reducer, dispatch } = useElements();
  const { loader } = reducer;

  useEffect(() => {
    dispatch(redirectToYubi());
  }, []);
  return (
    <>
      {loader ? (
        <SimpleBackdrop open={true} />
      ) : (
        <div className="yubi_screen_container">
          <WealthHeader heading="" profilePic={false} backTo={returnBackToApp} />
          <div className="yubi_container">
            <span className="error_text_yubi">Sorry:( , redirection failed!</span>
            <button onClick={returnBackToApp} className="back_btn_yubi">
              back to disboard
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default Yubi;
