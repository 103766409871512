import React, { useEffect, useState, memo } from "react";
import { useLocation } from "react-router-dom";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { validateUrl } from "../validations/formValidations";
import {
  cityWiseLoanData,
  companySectionData,
  legalSectionData,
  resourceSectionData,
  socialMediaLinksData
} from "./footerData";
import { PATH_WITH_DIFFERENT_BG_COLOR, PATH_WITH_FOOTER } from "./constant";
import "./footer.scss";
import { CITY_WISE_INSTANT_PERSONAL_LOANS } from "../../../utils/constant";
import FooterLogo from "../../../assets/images/footerLogo.webp";

const Footer = () => {
  const location = useLocation();
  const [footerDisplay, setFooterDisplay] = useState(false);
  const [changeBgColor, setChangeBgColor] = useState(false);

  useEffect(() => {
    const path = PATH_WITH_FOOTER.indexOf(location?.pathname);
    if (path > -1) setFooterDisplay(true);
    else setFooterDisplay(false);

    const bgPath = PATH_WITH_DIFFERENT_BG_COLOR.indexOf(location?.pathname);
    if (bgPath > -1) setChangeBgColor(true);
    else setChangeBgColor(false);
  }, [location?.pathname]);

  const handleRedirect = (cityRoute) => {
    if (validateUrl(cityRoute)) {
      window.location.href = `${cityRoute}`;
    } else {
      window.location.href = `${CITY_WISE_INSTANT_PERSONAL_LOANS}/${cityRoute}`;
    }
  };

  const cityWiseLoanLinks = () =>
    cityWiseLoanData?.map((city) => (
      <li key={city?.cityName}>
        <a onClick={() => handleRedirect(city?.cityRouteLink)} title={city?.cityName}>
          {city?.cityName}
        </a>
      </li>
    ));

  const socialMediaLinks = () =>
    socialMediaLinksData?.map(({ target, routeLink, text }) => (
      <a target={target} href={routeLink}>
        {text}
      </a>
    ));

  const list = (target, routeLink, text) => (
    <li>
      {" "}
      <a target={target} href={routeLink}>
        {text}
      </a>
    </li>
  );
  const companySection = () => companySectionData?.map(({ target, routeLink, text }) => list(target, routeLink, text));

  const resourceSection = () =>
    resourceSectionData?.map(({ target, routeLink, text }) => list(target, routeLink, text));

  const legalSection = () => legalSectionData?.map(({ target, routeLink, text }) => list(target, routeLink, text));

  return (
    <>
      {footerDisplay && (
        <footer
          className="footer"
          data-aos="fade-up"
          data-aos-duration="800"
          style={changeBgColor ? { backgroundColor: "#000" } : {}}
        >
          <div className="container">
            <div className="row mb-4 d-flex d-md-none">
              <div className="col-md-12 col-sm-12 col-5 text-left">
                <div className="footerLogo ">
                  <a href="/">
                    <img src={FooterLogo} alt="" />
                  </a>
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-7 mobile-social mt-3">{socialMediaLinks()}</div>
            </div>
            <div className="row">
              <div className="col-md-12 col-lg-6 text-left">
                <div className="footerLogo d-none d-md-block">
                  <a href="/">
                    <img src={FooterLogo} />
                  </a>
                </div>
                <p>
                  We offer loans up to ₹ 5,00,000 with repayment periods up-to 36 months. We offer interest rates
                  starting from 11.99% APR (Annual Percentage Rate), however rates may vary case to case. All loans are
                  paid through Equal Monthly Instalments (EMIs) via electronic payment. We do charge low platform fees
                  and have no other hidden costs.
                </p>
                <p>
                  Example: If a customer takes a loan of ₹10,000 for a period of 3 months, at an annual interest rate of
                  11.99% APR, then the customer will pay an EMI for 3 months of ₹3,400 per month. Total payment over 3
                  months will be ₹10,200 (including principal and Interest).
                </p>
              </div>
              <div className="col-md-12 col-lg-6 MobileFooterHide">
                <div className="row">
                  <div className="col-md-4 col-lg-3">
                    <h3>Company</h3>
                    <ul>{companySection()}</ul>
                  </div>
                  <div className="col-md-4 col-lg-3">
                    <h3>Resources</h3>
                    <ul>{resourceSection()}</ul>
                  </div>
                  <div className="col-md-4 col-lg-6">
                    <h3>Legal</h3>
                    <ul>{legalSection()}</ul>
                  </div>
                  <div className="col-lg-12">
                    <div className="citiesList">
                      <h3>Cities</h3>
                      <ul>{cityWiseLoanLinks()}</ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mobileFooter">
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <Typography>Company</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>{companySection()}</ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
                    <Typography>Resources</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>{resourceSection()}</ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
                    <Typography>Legal</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>{legalSection()}</ul>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
                    <Typography>Cities</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <>
                      <div className="citiesList">
                        <ul>{cityWiseLoanLinks()}</ul>
                      </div>
                    </>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
          </div>
          <div className="footerBottom">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-md-6 text-left footerNone">{socialMediaLinks()}</div>
                <div className="col-md-6 text-left">
                  <p>{`© ${new Date().getFullYear()} by Stashfin`}</p>
                  <div className="powered-by-2">
                    <p>Trademark of EQX Analytics Private Limited</p>
                    <p>Credit enhancements (which may include funding or guarantee) by:</p>
                    <p>
                      <span>Akara Capital Advisors Private Limited</span>
                    </p>
                  </div>
                </div>
                <div className="col-4"></div>
                <div className="col-8 text-end text-md-end legel"></div>
              </div>
            </div>
          </div>
        </footer>
      )}
    </>
  );
};

export default memo(Footer);
