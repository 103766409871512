import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import {
  LOAN_CALCULATOR,
  PARTNER_LINK,
  TERM_CONDITIONS,
  PRIVACY_POLICY,
  PRIVACY_POLICY_AKARA,
  GRIEVANCE_REDRESSAL_MECHANISM,
  ARMY_LINK,
  CUSTOMER_SERVICE,
  SITE_MAP,
  WHY_US_NEW,
  INSTANT_PERSONAL_LOANS,
  INVESTOR_AND_MEDIA_RELATIONS,
  CONTACT_US
} from "../../../utils/constant";

import Certificate from "../../../assets/doc/certificate.pdf";

const nbfcText = () => (
  <>
    NBFC Certificate <br />{" "}
    <small> (Follow this link to search for Akara Capital Advisors Pvt Ltd in the list of RBI Regulated NBFCs)</small>
  </>
);

export const cityWiseLoanData = [
  { cityName: "Delhi", cityRouteLink: "delhi" },
  { cityName: "Chandigarh", cityRouteLink: "chandigarh" },
  { cityName: "Bangalore", cityRouteLink: "bangalore" },
  { cityName: "Hyderabad", cityRouteLink: "hyderabad" },
  { cityName: "Indore", cityRouteLink: "indore" },
  { cityName: "Mumbai", cityRouteLink: "mumbai" },
  { cityName: "Pune", cityRouteLink: "pune" },
  { cityName: "Chennai", cityRouteLink: "chennai" },
  { cityName: "Kolkata", cityRouteLink: "kolkata" },
  { cityName: "Gurugram", cityRouteLink: "gurugram" },
  { cityName: "Noida", cityRouteLink: "noida" },
  { cityName: "Lucknow", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-lucknow/" },
  { cityName: "Bhopal", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-bhopal/" },
  { cityName: "Agra", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-agra/" },
  { cityName: "Dehradun", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-dehradun/" },
  { cityName: "Kanpur", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-kanpur" },
  { cityName: "Nagpur", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-nagpur/" },
  { cityName: "Aurangabad", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-aurangabad/" },
  { cityName: "Madurai", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-madurai/" },
  { cityName: "Salem", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-salem/" },
  { cityName: "Rajkot", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-rajkot/" },
  { cityName: "Kurnool", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-kurnool/" },
  { cityName: "Vellore", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-vellore/" },
  {
    cityName: "Krishna, Andhra-Pradesh",
    cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-krishna-andhra-pradesh/"
  },
  { cityName: "Warangal", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-warangal/" },
  { cityName: "Anantapur", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-anantapur/" },
  { cityName: "Patna", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-patna/" },
  { cityName: "Karimnagar", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-karimnagar/" },
  { cityName: "Ludhiana", cityRouteLink: "https://blog.stashfin.com/credit-line-personal-loans-in-ludhiana/" }
];

export const socialMediaLinksData = [
  { target: "_blank", routeLink: "https://www.instagram.com/stashfin_", text: <InstagramIcon /> },
  { target: "_blank", routeLink: "https://www.facebook.com/stashfin.official", text: <FacebookRoundedIcon /> },
  { target: "_blank", routeLink: "https://in.linkedin.com/company/stashfin", text: <LinkedInIcon /> },
  { target: "_blank", routeLink: "https://youtube.com/channel/UCyjtuSfqPbbHcu07CZ1eqEg", text: <YouTubeIcon /> }
];

export const companySectionData = [
  { target: "_self", routeLink: "/", text: "Home" },
  { target: "_self", routeLink: `${INSTANT_PERSONAL_LOANS}`, text: "Instant Personal Loans" },
  { target: "_self", routeLink: `${WHY_US_NEW}`, text: "Why Us" },
  { target: "_self", routeLink: "/about-us", text: "About Us" },
  // { target: "_self", routeLink: "/careers", text: "Careers" },
  { target: "_self", routeLink: `${CUSTOMER_SERVICE}`, text: "Customer Service" },
  { target: "_blank", routeLink: `${INVESTOR_AND_MEDIA_RELATIONS}`, text: "Investors and Media Queries" },
  { target: "_self", routeLink: `${ARMY_LINK}`, text: "Sentinel" },
  { target: "_self", routeLink: `${SITE_MAP}`, text: "Sitemap" },
  { target: "_self", routeLink: `${CONTACT_US}`, text: "Contact Us" }
];

export const resourceSectionData = [
  { target: "_self", routeLink: "https://blog.stashfin.com/", text: "Blog" },
  { target: "_self", routeLink: "/newsroom", text: "News" },
  { target: "_self", routeLink: "/customer-services", text: "FAQs" },
  { target: "_self", routeLink: `${LOAN_CALCULATOR}`, text: "Calculator" },
  { target: "_self", routeLink: `${PARTNER_LINK}`, text: "Partners" }
];

export const legalSectionData = [
  { target: "_self", routeLink: `${PRIVACY_POLICY}`, text: "Privacy Policy (EQX)" },
  { target: "_self", routeLink: `${PRIVACY_POLICY_AKARA}`, text: "Privacy Policy (AKARA)" },
  { target: "_self", routeLink: `${TERM_CONDITIONS}`, text: "Terms and Conditions" },
  { target: "_self", routeLink: `${GRIEVANCE_REDRESSAL_MECHANISM}`, text: "Grievance Redressal Mechanism" },
  {
    target: "_blank",
    routeLink: "https://static.stashfin.com/pdfs/Akara_Interest_Rate_Policy.pdf",
    text: "Interest Rate Policy"
  },
  { target: '_blank', routeLink: `${Certificate}`, text: nbfcText() },
  { target: '_self', routeLink: '/website-disclosure', text: 'Disclosure Under Default Loss Guarantee Guidelines' },
  {
    target: "_blank",
    routeLink: "https://static.stashfin.com/pdfs/Data_Retention_Policy__.pdf",
    text: "Data Retention Policy"
  }
];
