import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import './HomeNew.scss'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';
import red_tick from '../../assets/images/red_tick.png'
import blackArrow from '../../assets/images/blackArrowRight.png'
import productIcon1 from '../../assets/images/productIcon1.png'
import productIcon2 from '../../assets/images/productIcon2.png'
import productIcon3 from '../../assets/images/productIcon3.png'
import productIcon4 from '../../assets/images/productIcon4.png'
import appstore from '../../assets/images/appleStore.png'
import playstore from '../../assets/images/googlePlayStore.png'
import horizontal_line from '../../assets/images/horizontal_line.png'
import chart from '../../assets/images/creditScoreChart.png'
import stars from '../../assets/images/stars.png'
import profile from '../../assets/images/profile.png'
import mobile from '../../assets/images/carousalImg1.png'
import homeBlog1 from '../../assets/images/homeBlog1.png'
import homeBlog2 from '../../assets/images/homeBlog2.png'
import homeBlog3 from '../../assets/images/homeBlog3.png'
import homeBlog4 from '../../assets/images/homeBlog4.png'
import faqPerson from '../../assets/images/faqPerson.png'
import downFaqArrow from '../../assets/images/downFaqArrow.png'
import person2 from '../../assets/images/person2.png'
import person3 from '../../assets/images/person3.png'
import { faqs, slides } from './HomeData';
import { useNavigate } from 'react-router-dom';

function HomeNew() {
  const navigate = useNavigate()
  const [openIndices, setOpenIndices] = useState([]);
  const toggleFAQ = (index) => {
    if (openIndices.includes(index)) {
      setOpenIndices(openIndices.filter((i) => i !== index)); // Close FAQ
    } else {
      setOpenIndices([...openIndices, index]); // Open FAQ
    }
  };
  const handleApplyClick = (type) => {
    if(type === "get Instant Funds"){
      window.scrollTo(0, 0);
      navigate("/signup")
    }else if(type === "few steps. Insure now"){
      window.scrollTo(0, 0);
      navigate("/insurance")
    }
    else if(type==="Cards of leading brands"){
        window.open("https://play.google.com/store/apps/details?id=com.stashfin.android", "_blamk")
    }
    else if(type==="in 10 seconds"){
      handleCheckCredit();
    }
  }
  const handleContactUs = () =>{
    window.scrollTo(0, 0);
    navigate("/customer-services")
  }
  
  const handleCheckCredit = () =>{
    window.scrollTo(0, 0);
    window.open('https://creditreport.stashfin.com/', '_blank');
  }
  return (
    <div className='newHomePage_container'>
      <div className='top_carousal_bannner_container'>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop={true}
          navigation={false}
          pagination={false}
        >
          {slides.map((item, index) => (
            <SwiperSlide key={index}>
              <div className="carousel-content">
                <div className='left_carousal_content_div'>
                  <span className='best_credit_line_text'>{item.toolTip}</span>
                  {item.toolTip === "Instant credit line" && <div className='heading_div'>
                    <span className='blue_heading heading'>Your Superpower to</span>
                    <span className='blue_heading heading'>get <span className='red_heading heading'>Instant Funds</span></span>
                  </div>}
                  {item.toolTip === "Hassle free insurance" && <div className='heading_div'>
                    <span className='blue_heading heading'><span className='red_heading heading'>Get Insurance</span> in just</span>
                    <span className='blue_heading heading'>few steps. Insure now</span>
                  </div>}
                  {item.toolTip === "Best discounts on brands" && <div className='heading_div'>
                    <span className='blue_heading heading'>Best Discounts on <span className='red_heading heading'>Gift</span></span>
                    <span className='blue_heading heading'><span className='red_heading heading'>Cards</span> of leading brands</span>
                  </div>}
                  {item.toolTip === "Check credit score" && <div className='heading_div'>
                    <span className='blue_heading heading'>Know your Credit Score</span>
                    <span className='red_heading heading'>in 10 seconds</span>
                  </div>}
                  <span className='sub_heading'>{item.sub_heading}</span>
                  <button className='apply_now_btn' onClick={()=>{handleApplyClick(item.heading2)}}>{item.btnText}</button>
                  <div className='tick_div'>
                    <div className='tick_option'>
                      <img src={red_tick} className='tick_red'></img>
                      <span className='option'>{item.points[0]}</span>
                    </div>
                    <div className='tick_option'>
                      <img src={red_tick} className='tick_red'></img>
                      <span className='option'>{item.points[1]}</span>
                    </div>
                    <div className='tick_option'>
                      <img src={red_tick} className='tick_red'></img>
                      <span className='option'>{item.points[2]}</span>
                    </div>
                  </div>
                </div>
                <div className='right_image_div'>
                  <img className={`image${index}`} src={item.img}></img>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className='ourProductsNBenefits_container'>
        <span className='ourProducts_heading'>Our offerings <br></br><br></br><span className='ourProducts_subHeading'>Discover our entire range of products designed to help with management of your finances</span></span>
        <div className='ourProducts_cards'>
          <div className='product_card'>
            <div className='product_content'>
              <img className='product_icon' src={productIcon1}></img>
              <span className='product_name'>Instant Loan</span>
              <span className='product_desc'>Get instant credit limit of up to Rs.5 Lakhs with flexible tenure and up to 30 days of interest free period</span>
            </div>
            <span className='discover_text' onClick={()=>{handleApplyClick("get Instant Funds")}}>Discover Now<img className='arrowBlackRight' src={blackArrow}></img></span>
          </div>
          <div className='product_card'>
            <div className='product_content'>
              <img className='product_icon' src={productIcon2}></img>
              <span className='product_name'>Insurance</span>
              <span className='product_desc'>Get comprehensive coverage ranging across Fraud Protection, Critical illness cover, travel insurance and more at affordable prices</span>
            </div>
            <span className='discover_text' onClick={()=>{handleApplyClick("few steps. Insure now")}}>Discover Now<img className='arrowBlackRight' src={blackArrow}></img></span>
          </div>
          <div className='product_card'>
            <div className='product_content'>
              <img className='product_icon' src={productIcon3}></img>
              <span className='product_name'>Gift Cards</span>
              <span className='product_desc'>Get best discounts on gift cards of all leading brands</span>
            </div>
            <span className='discover_text' onClick={()=>{handleApplyClick("Cards of leading brands")}}>Discover Now<img className='arrowBlackRight' src={blackArrow}></img></span>
          </div>
          <div className='product_card'>
            <div className='product_content'>
              <img className='product_icon' src={productIcon4}></img>
              <span className='product_name'>Credit Report</span>
              <span className='product_desc'>Get your detailed CIBIL Report and expert insights on how to improve or manage your score</span>
            </div>
            <span className='discover_text' onClick={()=>{handleApplyClick("in 10 seconds")}}>Discover Now<img className='arrowBlackRight' src={blackArrow}></img></span>
          </div>
        </div>
        <span className='benefits_heading'>Benefits of taking a credit line <br></br>from us</span>
        <div className='benefits_items'>
          <div className='benefit_item'>
            <span className='benefit_head'>Credit limit upto Rs. 5,00,000</span>
            <span className='benefit_desc'>Get credit limit in just 10 minutes!</span>
          </div>
          <div className='benefit_item'>
            <span className='benefit_head'>Up to 30 days of interest free credit period</span>
            <span className='benefit_desc'>Enjoy flexibility of payments</span>
          </div>
          <div className='benefit_item'>
            <span className='benefit_head'>Instant funds directly in bank account</span>
            <span className='benefit_desc'>Get funds for all your emergency expenses</span>
          </div>
          <div className='benefit_item'>
            <span className='benefit_head'>Flexible tenure up to 36 months</span>
            <span className='benefit_desc'>Decide the pace at which you want to pay</span>
          </div>
          <div className='benefit_item'>
            <span className='benefit_head'>Pay interest only on the utilized amount
            </span>
            <span className='benefit_desc'>Interest is charged only on the amount you draw and not your overall credit limit</span>
          </div>
        </div>
        <button className='apply_now_btn' onClick={()=>{handleApplyClick("get Instant Funds")}}>Apply Now</button>
        <div className='stores_div'>
          <a 
            href="https://apps.apple.com/us/app/stashfin/id1276990457"
            target="_blank"
            className="d-inline-block"
            rel="noreferrer"
          >
            <img className='store_img' src={appstore}></img>
          </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.stashfin.android"
              target="_blank"
              className="d-inline-block"
              rel="noreferrer"
            >
              <img className='store_img' src={playstore}></img>
            </a>
        </div>
      </div>
      <div className='apply_know_divs'>
          <div className='apply_div'>
            <div className='heading_sub_div'>
              <span className='heading'>Get your credit line in just 10 minutes</span>
              <span className='subheading'>Apply for your credit line from the comforts of your home through our 100% digital process</span>
            </div>
            <div className='steps_div'>
                <div className='number_text'>
                  <div className='number'>1</div>
                  <span className='step_text'>Download & login into <br></br>the Stashfin App</span>
                </div>
                <img className='horizontal_divider' src={horizontal_line}></img>
                <div className='number_text'>
                  <div className='number'>2</div>
                  <span className='step_text'>Apply for Credit <br></br>Line</span>
                </div>
                <img className='horizontal_divider' src={horizontal_line}></img>
                <div className='number_text'>
                  <div className='number'>3</div>
                  <span className='step_text'>Complete your <br></br>KYC</span>
                </div>
                <img className='horizontal_divider' src={horizontal_line}></img>
                <div className='number_text'>
                  <div className='number'>4</div>
                  <span className='step_text'>Transfer funds directly into <br></br>your bank account</span>
                </div>
              </div>
              <button className='apply_now_btn' onClick={()=>{handleApplyClick("get Instant Funds")}}>Apply Now</button>
          </div>
          <div className='know_credit_div'>
            <div className='know_left_content'>
              <span className='know_heading'>Check your Credit Score in just 10 seconds</span>
              <span className='know_desc'>Get expert advice on how to improve your credit score and keep it high</span>
              <button className='know_btn' onClick={handleCheckCredit}>Check credit score</button>
            </div>
            <img className='chart' src={chart}></img>
          </div>
      </div>
      <div className='smiling_faces_div'>
        <span className='smiling_heading'>We have over 10 Million App downloads on the Google Playstore</span>
        <div className='users_count_div'>
            <div className='happy_count'>
              <span className='count'>7.5 Million +</span>
              <span className='label'>Loans Disbursed</span>
            </div>
            <div className='happy_count'>
              <span className='count'>10,000 Cr + </span>
              <span className='label'>Amount Disbursed</span>
            </div>
            <div className='happy_count'>
              <span className='count'>Pan India </span>
              <span className='label'>Location Served</span>
            </div>
        </div>
        <div className='stores_div'>
            <a 
              href="https://apps.apple.com/us/app/stashfin/id1276990457"
              target="_blank"
              className="d-inline-block"
              rel="noreferrer"
            >
              <img className='store_img' src={appstore}></img>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.stashfin.android"
              target="_blank"
              className="d-inline-block"
              rel="noreferrer"
            >
              <img className='store_img' src={playstore}></img>
            </a>
        </div>
      </div>
      <div className='testimonial_div'>
        <div className='testi_heading_desc_div'>
          <span className='testi_heading'>Testimonials</span>
          <span className='testi_desc'>Read what our customers have to say about us</span>
        </div>
        <div className='testi_cards_div'>
          <div className='testi_card'>
            <img className='stars' src={stars}></img>
            <span className='testi_review'>Stashfin made my personal loan application seamless. The service was rapid, the process was smooth, and my loan was approved effortlessly. Excellent experience!</span>
            <div className='person_info_div'>
              <img className='person' src={profile}></img>
              <div className='reviewer_details'>
                <span className='reviewer_name'>Kunal Gupta</span>
                <span className='review_date'>June 26, 2024</span>
              </div>
            </div>
          </div>
          <div className='testi_card'>
            <img className='stars' src={stars}></img>
            <span className='testi_review'>Thank you, Stashfin, for the prompt loan during my family emergency. The process was simple, quick, and stress-free</span>
            <div className='person_info_div'>
              <img className='person' src={person2}></img>
              <div className='reviewer_details'>
                <span className='reviewer_name'>Ajay Kumar</span>
                <span className='review_date'>April 4, 2023</span>
              </div>
            </div>
          </div>
          <div className='testi_card'>
            <img className='stars' src={stars}></img>
            <span className='testi_review'>Stashfin made securing a loan for my child's education fees simple. I just downloaded the app and completed the application within minutes</span>
            <div className='person_info_div'>
              <img className='person' src={person3}></img>
              <div className='reviewer_details'>
                <span className='reviewer_name'>Yogesh Singh</span>
                <span className='review_date'>Jan 20, 2022</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='last_inwards_div'>
        <div className='ready_div'>
          <div className='left_ready_div'>
              <span className='ready_heading'>Ready for loan to be <br></br>frustration-free?</span>
              <span className='ready_desc'>Get 100% digital instant loans directly in your bank account<br></br>only on Stashfin</span>
              <div className='stores_div'>
              <a
                href="https://apps.apple.com/us/app/stashfin/id1276990457"
                target="_blank"
                className="d-inline-block"
                rel="noreferrer"
              >
                <img className='store_img' src={appstore}></img>
              </a>
              <a 
                href="https://play.google.com/store/apps/details?id=com.stashfin.android"
                target="_blank"
                className="d-inline-block"
                rel="noreferrer"
              >
                <img className='store_img' src={playstore}></img>
              </a>
              </div>
          </div>
          <img className='right_ready_img' src={mobile}></img>
        </div>
        <div className='home_blog_div'>
          <div className='blog_head_desc_div'>
            <span className='blogs_heading'>Making money matters simple</span>
            <span className='blogs_desc'>Find tips & tricks to managing your money better</span>
          </div>
          <div className='blogs_cards_div'>
            <div className='blog_card'>
              <img className='blog_image' src={homeBlog1}></img>
              <span className='blog_heading'>What is Debt Trap and Six Ways to Avoid</span>
              <span className='blog_desc'>‘Don’t bite off more than you can chew’ is something we’ve often heard. It is  also something that few seem to follow. That people would like to access funds, and additional funds to... <span className='readMoreHome' onClick={()=>{window.open("https://blog.stashfin.com/debt-trap-explained-solutions/", '_blank')}}>read more</span></span>
            </div>
            <div className='blog_card'>
              <img className='blog_image' src={homeBlog2}></img>
              <span className='blog_heading'>Personal Financial Planning: The Importance</span>
              <span className='blog_desc'>The Importance of Healthy Financial Habits Spend wisely, save smartly, and lead a long, wealthy life You earn enough to make ends meet. You pay your bills on time.... <span className='readMoreHome' onClick={()=>{window.open("https://blog.stashfin.com/healthy-financial-habits/", '_blank')}}>read more</span></span>
            </div>
            <div className='blog_card'>
              <img className='blog_image' src={homeBlog3}></img>
              <span className='blog_heading'>Loan Protection Insurance Plan Including Critical</span>
              <span className='blog_desc'>In the current times, managing financial risk is of utmost importance as we all have witnessed the Covid-19 phase in our lives. This pandemic has guided each and every individual that life is unpredictable... <span className='readMoreHome' onClick={()=>{window.open("https://blog.stashfin.com/loan-protection-insurance-plan-including-critical-illness-rider/", '_blank')}}>read more</span></span>
            </div>
            <div className='blog_card'>
              <img className='blog_image' src={homeBlog4}></img>
              <span className='blog_heading'>Best Investment Options in India for 2024</span>
              <span className='blog_desc'>Investing wisely is crucial for financial stability and security. Whether you’re a cautious investor or willing to take on more risk, India offers a variety of investment options. This guide breaks down some of the best investment choices.... <span className='readMoreHome' onClick={()=>{window.open("https://blog.stashfin.com/best-investment-options-in-india-for-2024/", '_blank')}}>read more</span></span>
            </div>
          </div>
        </div>
        <div className='FAQ_Section'>
          <div className='faq_heading_desc_div'>
            <span className='faq_heading'>Frequently asked questions</span>
            <span className='faq_desc'>Access answers to common queries in our FAQs</span>
          </div>
          <div className='faqs_section'>
              {faqs.map((faq, index) => (
                <div className="faq_ques_ans_div" key={index}>
                  <div className="faq_ques_div">
                    <span className="faq_ques">{faq.question}</span>
                    <img
                      src={downFaqArrow}
                      className={`
                      ${openIndices.includes(index) ? 'down_vector_faq up' : 'down_vector_faq'}
                        `}
                      onClick={() => toggleFAQ(index)}
                    ></img>
                  </div>
                  {openIndices.includes(index) && (
                    <div className="faq_ans">{faq.answer}</div>
                  )}
                </div>
              ))}
          </div>
          <div className='haveQues_div'>
            <div className='haveQ_left_content_div'>
              <span className='haveQ_haeding'>Have more questions?</span>
              <span className='haveQ_desc'>We got you! Get quick resolutions to your concerns</span>
              <button className='contact_btn' onClick={handleContactUs}>Contact Us</button>
            </div>
            <img className='faq_person_img' src={faqPerson}></img>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeNew
