import {
  ADD_WEALTH_WAITLIST,
  GET_ALL_GRIP_BONDS,
  GET_BOND_DETAILS,
  GET_GRIP_REDIRECTION_LINK,
  GET_ORDERS,
  GET_USER_CONSENT_STATUS,
  GET_WEALTH_WAITLIST,
  IS_BACK,
  REDEEM_NOW,
  REGISTER_USER_TO_GRIP,
  REGISTER_USER_TO_GRIP_SUCCESS,
  RESET_REDEEM,
  SET_BOND_DETAILS,
  SET_CURRENT_SCREEN,
  SET_ORDERS,
  SET_REGISTERED_ON_GRIP,
  YUBI_REDIRECT,
  CLEAR_ERROR,
  PRIORITY_LIST_FORM_RESPONSES,
  VERIFY_ACCOUNT_PAGE_SHOWN,
  JOURNEY_END_PAGE_SHOWN,
  USER_KYC_MODAL_SHOWN,
  SET_BOND_ASSET_ID,
  GENERATE_WEALTH_OTP,
  RESET_WEALTH_OTP,
  GET_PARTNERS_DETAILS,
  SET_LOADER_FALSE,
  VERIFY_WEALTH_OTP,
  RESET_VERIFY_OTP_DATA,
  SET_SELECTED_BOND,
} from "./actionTypes";

export const setCurrentScreen = (payload) => ({
  type: SET_CURRENT_SCREEN,
  payload
});

export const getUserConsentStatus = () => ({
  type: GET_USER_CONSENT_STATUS
});

export const registerUserToGrip = (payload) => ({
  type: REGISTER_USER_TO_GRIP,
  payload
});

export const registerUserToGripSuccess = (payload) => ({
  type: REGISTER_USER_TO_GRIP_SUCCESS,
  payload
});

export const getAllGripBonds = () => ({
  type: GET_ALL_GRIP_BONDS
});

export const getBondDetails = (payload) => ({
  type: GET_BOND_DETAILS,
  payload
});

export const resetBondDetails = () => ({
  type: SET_BOND_DETAILS
});

export const getGripRedirectionLink = (payload) => ({
  type: GET_GRIP_REDIRECTION_LINK,
  payload
});

export const resetOrders = () => ({
  type: SET_ORDERS
});

export const getOrders = () => ({
  type: GET_ORDERS
});
export const redeemNow = (payload) => ({
  type: REDEEM_NOW,
  payload
});

export const resetWealthReferral = (payload) => ({
  type: RESET_REDEEM,
  payload
});

export const redirectToYubi = () => ({
  type: YUBI_REDIRECT
});

export const isBackToPartners = (payload) => ({
  type: IS_BACK,
  payload
});

export const getWealthWaitlist = () => ({
  type: GET_WEALTH_WAITLIST
});

export const addWealthWaitlist = (payload) => ({
  type: ADD_WEALTH_WAITLIST,
  payload
});

export const clearErrorAction = () => ({
  type: CLEAR_ERROR
});

export const setPriorityListFormResponses = (payload) => ({
  type: PRIORITY_LIST_FORM_RESPONSES,
  payload
});

export const setRegisteredOnGrip = (payload) => ({
  type: SET_REGISTERED_ON_GRIP
});

export const verifyAccountPageShown = (payload) => ({
  type: VERIFY_ACCOUNT_PAGE_SHOWN,
  payload
});

export const journeyEndPageShown = (payload) => ({
  type: JOURNEY_END_PAGE_SHOWN,
  payload
})

export const userKycModalShown = (payload) => ({
  type: USER_KYC_MODAL_SHOWN,
  payload
})

export const setBondAssetId = (payload) => ({
  type: SET_BOND_ASSET_ID,
  payload
});

export const generateWealthOtp = (payload) => ({
  type: GENERATE_WEALTH_OTP,
  payload
});

export const resetOtpGenerated = () => ({
  type: RESET_WEALTH_OTP,
});

export const getPartnersDetails = () => ({
  type: GET_PARTNERS_DETAILS,
});

export const setLoaderFalse = () => ({
  type: SET_LOADER_FALSE,
});

export const verifyOtp = (payload) => ({
  type: VERIFY_WEALTH_OTP,
  payload
});

export const resetVerifyOtpData = () => ({
  type: RESET_VERIFY_OTP_DATA
})

export const setSelectedBond = (payload) => ({
  type: SET_SELECTED_BOND,
  payload
})